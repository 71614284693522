import React, { Suspense, useContext } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import "./scss/common.scss";
//import "react-select/dist/react-select.min.css";
import "./scss/reactselect.scss";
import "./scss/reactphone.scss";
import "./scss/reactpopover.scss";
import "./scss/reactdates.scss";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { AUTH_TOKEN, TOKEN_TYPE } from "./config/constants";
import { createUploadLink } from "apollo-upload-client";
import LoadingSpinner from "./components/LoadingSpinner";
import { retry } from "./utils/RetryLoadable";
import DefaultLayout from "./containers/DefaultLayout";
// import Reservation from "./pages/reservation/Reservation";
// import routes from "./routes";
import { ThemeContext, lightTheme, darkTheme } from "./Theme";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./global";

const Login = React.lazy(() => retry(() => import("./pages/login/Login")));
const GoogleLogin = React.lazy(() =>
  retry(() => import("./pages/login/GoogleLogin")),
);
const RegisterPeople = React.lazy(() =>
  retry(() => import("./pages/arranger/RegisterPeople")),
);

const authLink = setContext((_, { headers }) => {
  const tokenType = localStorage.getItem(TOKEN_TYPE);
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `${tokenType} ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(
    ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(
            ({ debugMessage, message, locations, path }) => {
              if (debugMessage && debugMessage === "Unauthenticated.") {
                localStorage.removeItem(AUTH_TOKEN);
                window.location.href = "/";
              }

              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
              );
            },
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),
      createUploadLink({
        uri:
          process.env.REACT_APP_BACKEND_SERVER +
          process.env.REACT_APP_GRAPHQL_SERVER,
      }),
    ]),
  ),
  cache: new InMemoryCache(),
});

function App() {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <ThemeProvider theme={theme !== "dark-theme" ? lightTheme : darkTheme}>
      <ApolloProvider client={client}>
        <GlobalStyles />
        <div className={`App ${theme}`}>
          <HashRouter>
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  element={<Login />}
                />
                <Route
                  exact
                  path="/google-cb"
                  name="Login"
                  element={<GoogleLogin />}
                />
                <Route
                  exact
                  path="/register"
                  name="Register"
                  element={<RegisterPeople />}
                />

                <Route
                  path="*"
                  name="Home"
                  element={
                    <DefaultLayout theme={theme} toggleTheme={toggleTheme} />
                  }
                />
              </Routes>
            </Suspense>
          </HashRouter>
        </div>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
